import React from 'react'
import CandlestickChartSharpIcon from '@mui/icons-material/CandlestickChartSharp';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
export default function CellExpanderFormatter({
    tabIndex,
    expanded,
    onCellExpand
}) {

    function handleKeyDown(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onCellExpand();
        }
    }

    return (
        <div className="cellExpand">
            <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
                <span tabIndex={tabIndex}>{expanded ? <CandlestickChartSharpIcon/> : <CandlestickChartOutlinedIcon/>}</span>
            </span>
        </div>
    )
}
