import React, { useEffect } from 'react';
import { Stage, Layer, Image } from "react-konva";
import useImage from 'use-image';
import { v4 as uuidv4 } from 'uuid';
import CircleTool from './tools/CircleTool';
import HorizontalLineTool from './tools/HorizontalLineTool';
import VerticalLineTool from './tools/VerticalLineTool';
import CrossHairHorizontal from './tools/CrossHairHorizontal';
import CrossHairVertical from './tools/CrossHairVertical';
import { CircularProgress } from '@mui/joy';
import { useChartStore } from "../../store/chart";
import DraggableDialog from './components/DraggableModal';
import UpArrowTool from './tools/UpArrowTool';
import DownArrowTool from './tools/DownArrowTool';

const ChartImage = ({stock}) => {
    const today = new Date();
    var offset = -300; //Timezone offset for EST in minutes.
    var estDate = new Date(today.getTime() + offset*60*1000);
    const formattedDate = estDate.toISOString().split('T')[0];
    const [image, status] = useImage('https://api.valueareas.com/chartmaker/' + stock.ticker + '/5/' + formattedDate + '/1050/550/0/0/D/0');
    if (status === 'loading') return <CircularProgress />
    return <Image x={-10} image={image} alt="chart" />;
};

function ChartStage({stock}) {

    const addVerticalLine = useChartStore(state => state.addVerticalLine);
    const stageRef = React.useRef(null);
    const stageWidth = useChartStore((state) => state.stageWidth)
    const stageHeight = useChartStore((state) => state.stageHeight)
    const chartConfig = useChartStore((state) => state.chartConfig)
    const currentPendingDrawing = useChartStore((state) => state.currentPendingDrawing)
    const currentEditingItem = useChartStore((state) => state.currentEditingItem)
    const isDrawing = useChartStore((state) => state.isDrawing)
    const showHorizontalCrosshair = useChartStore((state) => state.showHorizontalCrosshair)
    const showVerticalCrosshair = useChartStore((state) => state.showVerticalCrosshair)
    const verticalLines = useChartStore((state) => state.verticalLines)

    const shapes = useChartStore((state) => state.shapes)
    const addShape = useChartStore((state) => state.addShape)
    const setShapes = useChartStore((state) => state.setShapes)
    const selectedId = useChartStore((state) => state.selectedId)
    const setSelectedId = useChartStore((state) => state.setSelectedId)  

    const [mousePos, setMousePos] = React.useState({ x: 0, y: 0 });
 
    useEffect(() => {
    }, [isDrawing])
    const handleMouseDownStage = (e) => {
        // deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedId(null);
            return;
        }

        // no drawing - skipping
        if (!isDrawing) {
            return;
        }

        switch (currentPendingDrawing) {
            case "ADD_HORIZONTAL_LINE": {
                let stage = stageRef.current.getStage();
                let pointer = stage.getPointerPosition();
                let shapeProps = {
                    id: uuidv4(),
                    points: [
                        0,
                        pointer.y + 2,
                        stageWidth - chartConfig.xpadding,
                        pointer.y + 2,
                    ],
                    strokeWidth: 2,
                    fill: "black",
                    stroke: "black",
                };
                addShape(shapeProps, 'HORIZONTAL_LINE');
                break;
            }
            case "ADD_VERTICAL_LINE": {
                let stage = stageRef.current.getStage();
                let pointer = stage.getPointerPosition();
                let shapeProps = {
                    id: uuidv4(),
                    points: [
                        pointer.x,
                        0, // chartConfig.ypadding
                        pointer.x,
                        stageHeight - chartConfig.ypadding,
                    ],
                    strokeWidth: 2,
                    fill: "black",
                    stroke: "black",
                };
                addShape(shapeProps, 'VERTICAL_LINE');
                break;
            }
            case "ADD_UP_ARROW": {
                let stage = stageRef.current.getStage();
                let pointer = stage.getPointerPosition();
                let shapeProps = {
                    id: uuidv4(),
                    x: pointer.x,
                    y: pointer.y,
                    width: 28,
                    height: 22,
                    color: "green",
                };
                addShape(shapeProps, 'UP_ARROW');
                break;
            }
            case "ADD_DOWN_ARROW": {
                let stage = stageRef.current.getStage();
                let pointer = stage.getPointerPosition();
                let shapeProps = {
                    id: uuidv4(),
                    x: pointer.x,
                    y: pointer.y,
                    width: 28,
                    height: 22,
                    color: "red",
                };
                addShape(shapeProps, 'DOWN_ARROW');
                break;
            }
        }
    }

    const handleMouseUpStage = (e) => {

    }

    const handleMouseMoveStage = (e) => {
        const mousePos = stageRef.current.getStage().getPointerPosition();
        let x = parseInt(mousePos.x) + 1;
        let y = parseInt(mousePos.y) + 3;
        setMousePos({ x: x, y: y });
    }
    return (
        <>
            <Stage
                width={stageWidth}
                height={stageHeight}
                ref={stageRef}
                onTouchStart={handleMouseDownStage}
                onMouseDown={handleMouseDownStage}
                onMouseUp={handleMouseUpStage}
                onMouseMove={handleMouseMoveStage}
                style={{ cursor: 'crosshair' }}
            >
                <Layer>
                    <ChartImage stock={stock}/>
                    <UpArrowTool x={40} y={347} width={28} height={22} color="green" />
                    {showVerticalCrosshair && <CrossHairVertical stageRef={stageRef} mousePos={mousePos} />}
                    {showHorizontalCrosshair && <CrossHairHorizontal stageRef={stageRef} mousePos={mousePos} />}
                    {
                        shapes.map((shape, index) => {
                            switch (shape.type) {
                                case 'HORIZONTAL_LINE':
                                    return <HorizontalLineTool
                                        key={index}
                                        shapeProps={shape.shapeProps}
                                        isSelected={shape.shapeProps.id === selectedId}
                                        onSelect={() => {
                                            setSelectedId(shape.shapeProps.id);
                                        }}
                                        onChange={(newAttrs) => {
                                            //const shapes = shapes.slice();
                                            console.log('new attrs', newAttrs);
                                            shapes[index].shapeProps = newAttrs;
                                            setShapes(shapes);
                                        }}
                                        stageRef={stageRef} />
                                case 'VERTICAL_LINE':
                                    return <VerticalLineTool
                                        key={index}
                                        shapeProps={shape.shapeProps}
                                        isSelected={shape.shapeProps.id === selectedId}
                                        onSelect={() => {
                                            setSelectedId(shape.shapeProps.id);
                                        }}
                                        onChange={(newAttrs) => {
                                            //const shapes = shapes.slice();
                                            console.log('new attrs', newAttrs);
                                            shapes[index].shapeProps = newAttrs;
                                            setShapes(shapes);
                                        }}
                                        stageRef={stageRef}
                                    />
                                case 'UP_ARROW':
                                    return <UpArrowTool
                                        key={index}
                                        shapeProps={shape.shapeProps}
                                        isSelected={shape.shapeProps.id === selectedId}
                                        onSelect={() => {
                                            setSelectedId(shape.shapeProps.id);
                                        }}
                                        onChange={(newAttrs) => {
                                            //const shapes = shapes.slice();
                                            console.log('new attrs', newAttrs);
                                            shapes[index].shapeProps = newAttrs;
                                            setShapes(shapes);
                                        }}
                                        stageRef={stageRef} />
                                case 'DOWN_ARROW':
                                    return <DownArrowTool
                                        key={index}
                                        shapeProps={shape.shapeProps}
                                        isSelected={shape.shapeProps.id === selectedId}
                                        onSelect={() => {
                                            console.log('select shape', shape.shapeProps.id)
                                            setSelectedId(shape.shapeProps.id);
                                        }}
                                        onChange={(newAttrs) => {
                                            //const shapes = shapes.slice();
                                            console.log('new attrs', newAttrs);
                                            shapes[index].shapeProps = newAttrs;
                                            setShapes(shapes);
                                        }}
                                        stageRef={stageRef} />
                                default:
                                    return null;
                            }
                        })
                    }
                    {
                        verticalLines.map((line, index) => {
                            return <VerticalLineTool key={index} line={line} stageRef={stageRef} />
                        })
                    }
                </Layer>
            </Stage>
        </>
    );
}

export default ChartStage;