import React from 'react'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export default function Check({ value }) {

    if (value == true) {
        return <CheckBoxIcon />
    } else {
        return <CloseOutlinedIcon />
    }

}
