import React from 'react'
import { Group, Line, Rect, Transformer, Circle } from "react-konva";
import { useChartStore } from "../../../store/chart";

export default function VerticalLineTool({ shapeProps, isSelected, onSelect, onChange, stageRef }) {
    const stageWidth = useChartStore((state) => state.stageWidth)
    const stageHeight = useChartStore((state) => state.stageHeight)
    const chartConfig = useChartStore((state) => state.chartConfig)
    const [isDragging, setIsDragging] = React.useState(false);
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
        if (isSelected) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    return (
        <>
            <Group draggable
                ref={shapeRef}
                onDragStart={() => {
                    stageRef.current.getStage().container().style.cursor = "grabbing";
                    setIsDragging(true);
                }}

                onMouseEnter={() => {
                    stageRef.current.getStage().container().style.cursor = "pointer";
                }}
                onMouseLeave={() => {
                    stageRef.current.getStage().container().style.cursor = "crosshair";
                }}
                onDragMove={e => {
                    const node = shapeRef.current;
                    node.y(0);
                    /*const newPos = e.target.position();
                    // reset position to its old state
                    // so drag is fully controlled by react
                    e.target.position({ x: pos.x, y: pos.y });
        
                    // your set state
                    setPos({
                        x: Math.min(chartConfig.stageWidth, newPos.x),
                        y: Math.min(chartConfig.stageHeight, newPos.y)
                    });*/
                }}
            >
                <Line
                    {...shapeProps}
                    onClick={onSelect}
                    onTap={onSelect} 
                    onMouseEnter={(e) => {

                        let rect = stageRef.current.getStage().findOne("." + shapeProps.name + "_rectleft");
                        if (rect) {
                            rect.show();
                        }

                        let rect1 = stageRef.current.getStage().findOne("." + shapeProps.name + "_rectright");
                        if (rect1) {
                            rect1.show();
                        }

                    }}
                    onMouseLeave={() => {
                        let rect = stageRef.current.getStage().findOne("." + shapeProps.name + "_rectleft");
                        if (rect) {
                            rect.hide();
                        }

                        let rect1 = stageRef.current.getStage().findOne("." + shapeProps.name + "_rectright");
                        if (rect1) {
                            rect1.hide();
                        }
                    }}
                    onTransformEnd={(e) => {
                        // transformer is changing scale of the node
                        // and NOT its width or height
                        // but in the store we have only width and height
                        // to match the data better we will reset scale on transform end
                        const node = shapeRef.current;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        // we will reset it back
                        //node.scaleX(1);
                        //node.scaleY(1);
                        onChange({
                            ...shapeProps,
                            x: node.x(),
                            y: node.y(),
                            // set minimal value
                            width: node.width(),
                            height: node.height(),
                        });
                    }}
                    onDragEnd={(e) => {
                        onChange({
                            ...shapeProps,
                            x: e.target.x(),
                            y: e.target.y(),
                        });
                        stageRef.current.getStage().container().style.cursor = "pointer";
                        setIsDragging(false);
                    }}
                />
                <Circle
                    x={shapeProps.points[0]}
                    y={shapeProps.points[1] + 6 }
                    visible={false}
                    name={shapeProps.name + "_rectleft"}
                    width={10}
                    stroke={"blue"}
                    height={10} 
                    onMouseEnter={(e) => {

                    }}
                    onMouseLeave={() => {
                    }}
                    fill="white"
                />
                <Circle
                    x={shapeProps.points[2]}
                    y={shapeProps.points[3]}
                    visible={false}
                    stroke={"blue"}
                    strokeWidth={1}
                    name={shapeProps.name + "_rectright"}
                    width={10}
                    height={10} 
                    onMouseEnter={(e) => {

                    }}
                    onMouseLeave={() => {
                    }}
                    fill="white"
                />
            </Group>
            {isSelected && (
                <Transformer
                    ref={trRef}
                    flipEnabled={false}
                    resizeEnabled={false}
                    rotateEnabled={false}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </>
    )
}
