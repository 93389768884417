import * as React from 'react';


import { useScreenerStore } from "../../store/screener";
import Tooltip from '@mui/joy/Tooltip';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function PriceibFilter() {
    const setItemValue = useScreenerStore(state => state.setItemValue)
    let itemValue = useScreenerStore((state) => state.screenerCriteria.cibCloseStatus)

    const handleChange = async (event, newItemValue) => {
        console.log('newItemValue', newItemValue)
        if (itemValue == newItemValue || newItemValue == "" || newItemValue == null) {
            newItemValue = null
        }
        await setItemValue('cibCloseStatus', newItemValue);
    };

    //console.log('render valuearea filter')
    //console.log('itemValue', itemValue)

    if (itemValue == null) {
        itemValue = ""
    }

    return (
        <FormControl size="sm">
            <Tooltip title="Filter current stock price relative to the day's initial balance (9.30am to 10.30am EST price range)." variant="solid" arrow placement="top">
                <FormLabel>PRICE:IB<InfoOutlinedIcon /> </FormLabel></Tooltip>
            <Select
                size="sm"
                placeholder="Filter by status"
                value={itemValue}
                onChange={handleChange}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            >
                <Option value="">Any</Option>
                <Option value="Price Above IB">Price Above IB</Option>
                <Option value="Price Within IB">Price Within IB</Option>
                <Option value="Price Below IB">Price Below IB</Option>
            </Select>
        </FormControl>
    );
}