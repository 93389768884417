import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';

export default function MiniChart({ row }) {
    const today = new Date();
    var offset = -300; //Timezone offset for EST in minutes.
    var estDate = new Date(today.getTime() + offset*60*1000);
    const formattedDate = estDate.toISOString().split('T')[0];
    //console.log(row)

    return (
        <Card sx={{ width: 590, m:2 }}>
            <div>
                <Typography level="title-lg">{row.ticker} Intraday Volume Profile Chart</Typography>
                <Typography level="body-sm">${row.row.cClose}</Typography> 
            </div>
           
                <img
                    src={"https://api.valueareas.com/chartmaker/" + row.ticker + "/5/" + formattedDate + "/576/313/0/1/D/0"}
                    loading="lazy"
                    alt=""
                />
           
            <CardContent orientation="horizontal">
                <div>
                    <Typography level="body-xs">Currently Trading:</Typography>
                    <Typography fontSize="lg" fontWeight="lg">
                        {row.row.cclosestatus}
                    </Typography>
                </div> 
            </CardContent>
        </Card>
    );
}