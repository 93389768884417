import 'react-data-grid/lib/styles.css';
import React, { useMemo, useState } from "react";
import DataGrid from 'react-data-grid';
import { useScreenerStore } from "../store/screener";
import Placeholder from './Placeholder';
import CircularProgress from '@mui/joy/CircularProgress';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/joy/IconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel'; 
import Button from '@mui/joy/Button';
import CellExpanderFormatter from './CellExpanderFormatter'; 
import Check from './Check'; 
import ValueareaFilter from './screener/ValueareaFilter';
import OpenPostVAFilter from './screener/OpenPostVAFilter';
import PdhlFilter from './screener/PdhlFilter';
import IbBreakoutFilter from './screener/IbBreakoutFilter';
import PriceibFilter from './screener/PriceibFilter';
import IbsizeFilter from './screener/IbsizeFilter';
import MiniDetail from './MiniDetail';
import TickerCellExpanderFormatter from './TickerCellExpanderFormatter';

function formatCompactNumber(number) {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(number);
}


const columns = [
    {
        key: 'expanded',
        name: '',
        minWidth: 30,
        width: 30,
        colSpan(args) {
            if (args.type === 'ROW' && args.row?.type === 'DETAIL') {
                return 16;
            }
        },
        cellClass(row) {
            return row.type === 'DETAIL'
                ? 'p24'
                : undefined;
        },
        renderCell({ row, tabIndex, onRowChange }) {
            //console.log('render cell row is', row)
            if (row.type === 'DETAIL') {
                return <MiniDetail row={row} />;
            }

            return (
                <CellExpanderFormatter
                    expanded={row.expanded}
                    tabIndex={tabIndex}
                    onCellExpand={() => {
                        onRowChange({ ...row, expanded: !row.expanded });
                    }}
                />
            );
        }
    },
    {
        key: 'ticker', name: 'Ticker', width: 80, 
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
        renderCell({ row, tabIndex, onRowChange }) {
            //console.log('render cell row is', row)
            if (row.type === 'DETAIL') {
                return <MiniDetail row={row} />;
            }

            return (
                 <TickerCellExpanderFormatter  expanded={row.expanded}
                 tabIndex={tabIndex}
                 row={row}
                 onCellExpand={() => {
                     onRowChange({ ...row, expanded: !row.expanded });
                 }}></TickerCellExpanderFormatter>
            );
        }
    },
    {
        key: 'cClose', name: 'Last', renderCell(props) {
            const value = props.row.cClose;
            if (value) {
                return (
                    <span style={{ fontSize: '11px', }}>
                        ${value.toFixed(2)}
                    </span>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'changep', name: 'Change', renderCell(props) {
            const valuev = props.row.changev;
            const valuep = props.row.changep;
            if (valuep && valuev) {
                const displayValue = '$' + valuev.toFixed(2) + " (" + valuep.toFixed(2) + "%)";
                return (
                    <span style={{ fontSize: '11px', }}>
                        {displayValue}
                    </span>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'cvolume', name: 'Volume', renderCell(props) {
            const valuev = props.row.cvolume;
            if (valuev) { 
                return (
                    <span style={{ fontSize: '11px', }}>
                        {formatCompactNumber(valuev)}
                    </span>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'cclosestatus', name: 'PRICE:PVA',
        renderCell(props) {
            const value = props.row.cclosestatus;
            if (value) {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            {value}
                        </span>
                    </>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'cOpenPosVA', name: 'OPEN:PVA',
        renderCell(props) {
            const value = props.row.cOpenPosVA;
            if (value) {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            {value}
                        </span>
                    </>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'cOpenPosHL', name: 'Open:PH-L',
        renderCell(props) {
            const value = props.row.cOpenPosHL;
            if (value) {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            {value}
                        </span>
                    </>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'ibBreakStatus', name: 'IB:Test', renderCell(props) {
            const value = props.row.ibBreakStatus;
            if (value) {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            {value}
                        </span>
                    </>
                );
            } else {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            -
                        </span>
                    </>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'cibCloseStatus', name: 'PRICE:IB', renderCell(props) {
            const value = props.row.cibCloseStatus;
            if (value) {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            {value}
                        </span>
                    </>
                );
            } else {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            -
                        </span>
                    </>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'ibSize', name: 'IB Size', renderCell(props) {
            const value = props.row.ibSize;
            if (value) {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            {value}
                        </span>
                    </>
                );
            } else {
                return (
                    <>
                        <span style={{ fontSize: '11px', }}>
                            -
                        </span>
                    </>
                );
            }
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'r1Hit', name: 'R1', width: 40, renderCell(props) {
            const valuev = props.row.r1Hit;

            return (
                <Check value={valuev} />
            );

        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'r2Hit', name: 'R2', width: 40, renderCell(props) {
            const valuev = props.row.r2Hit;

            return (
                <Check value={valuev} />
            );

        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 'r3Hit', name: 'R3', width: 20, renderCell(props) {
            const valuev = props.row.r3Hit;
            return (
                <Check value={valuev} />
            );
        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    

    {
        key: 's1Hit', name: 'S1', width: 40, renderCell(props) {
            const valuev = props.row.s1Hit;

            return (
                <Check value={valuev} />
            );

        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 's2Hit', name: 'S2', width: 40, renderCell(props) {
            const valuev = props.row.s2Hit;

            return (
                <Check value={valuev} />
            );

        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },
    {
        key: 's3Hit', name: 'S3', width: 40, renderCell(props) {
            const valuev = props.row.s3Hit;

            return (
                <Check value={valuev} />
            );

        },
        cellClass(row) {
            return row.cclosestatus === 'Above PVAH'
                ? 'bgGreen'
                :  (row.cclosestatus === 'Below PVAL' ? 'bgRed' : 'bgNeutral')
        },
    },

];

export default function ResultGrid() {

    const rows = useScreenerStore(state => state.screenerData);
    const setScreenerData = useScreenerStore(state => state.setScreenerData);
    const isRunning = useScreenerStore(state => state.isRunning);
    const runScreenerEndpoint = useScreenerStore(state => state.runScreenerEndpoint)
    const lastRun = useScreenerStore(state => state.lastRun)
    const [sortColumns, setSortColumns] = useState([]);

    const [order, setOrder] = React.useState("desc")
    const [selected, setSelected] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const renderFilters = () => (
        <React.Fragment>
            <ValueareaFilter></ValueareaFilter>
            <OpenPostVAFilter></OpenPostVAFilter>
            <PdhlFilter></PdhlFilter>
            <IbBreakoutFilter></IbBreakoutFilter>
            <PriceibFilter></PriceibFilter>
            <IbsizeFilter></IbsizeFilter>
        </React.Fragment>
    )

    function EmptyRowsRenderer() {
        return (
            <div style={{ textAlign: 'center', marginTop: '10px', gridColumn: '1/-1' }}>
                No matching symbols found
            </div>
        );
    }

    function getComparator(sortColumn) {
        switch (sortColumn) {
            case "cclosestatus":
            case "cOpenPosVA":
            case "cOpenPosHL":
            case "ibBreakStatus":
            case "cibCloseStatus":
            case "ibSize":
            case 'ticker':
                return (a, b) => {
                    return a[sortColumn]?.localeCompare(b[sortColumn]);
                };
            case "changep":
            case 's1Hit':
            case 's2Hit':
            case 's3Hit':
            case 'r1Hit':
            case 'r2Hit':
            case 'r3Hit':
            case 'cClose':
            case 'cvolume':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    let sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;

        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function onRowsChange(rows, { indexes }) {
        const row = rows[indexes[0]];
        //console.log('row changed', row);
        if (row.type === 'MASTER') {
            if (row.expanded) {
                rows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    id: row.id + 1,
                    parentId: row.id,
                    ticker: row.ticker,
                    row: row
                });
            } else {
                rows.splice(indexes[0] + 1, 1);
            }
            //console.log('rows are', rows)
            setScreenerData(rows); // update the store
            sortedRows = rows;
        }
    }

    return (
        <React.Fragment>
            
            <Sheet
                className="SearchAndFilters-mobile"
                sx={{
                    display: { xs: "flex", sm: "none" },
                    my: 1,
                    gap: 1
                }}
            > 
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => setOpen(true)}
                >
                    <FilterAltIcon />
                </IconButton>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose />
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            {renderFilters()}
                            <Button color="primary" onClick={() => { runScreenerEndpoint();setOpen(false)}}>
                                Run Screener
                            </Button>
                        </Sheet>
                    </ModalDialog>
                </Modal>
            </Sheet>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: "sm",
                    py: 2,
                    display: { xs: "none", sm: "flex" },
                    flexWrap: "wrap",
                    gap: 1.5,
                    "& > *": {
                        minWidth: { xs: "120px", md: "160px" }
                    }
                }}
            >
                
                {renderFilters()}
                <FormControl size="sm">
                    <FormLabel>&nbsp;</FormLabel>
                    <Button size="sm" color="primary" onClick={runScreenerEndpoint}>
                        Run Screener
                    </Button>
                </FormControl>
            </Box>

            {isRunning && <CircularProgress
                color="primary"
                determinate={false}
                variant="solid"
            />} 
            {!isRunning && <DataGrid
                columns={columns}
                rows={sortedRows}
                className={"rdg-light"} 
                onRowsChange={onRowsChange}
                enableVirtualization={true}
                rowHeight={(row) => (row.type === 'DETAIL' ? '520' : 25)}
                defaultColumnOptions={{
                    sortable: true,
                    resizable: true
                }}
                renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
                sortColumns={sortColumns}
                onSortColumnsChange={setSortColumns}
                style={{ height: '100vh' }} />}
        </React.Fragment>
    )
}