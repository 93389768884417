import * as React from 'react';
import { Box, IconButton } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ColorSchemeToggle from './ColorSchemeToggle';
import UserAuth from './auth/UserAuth';
import Button from '@mui/joy/Button';
import MobileMenu from './MobileMenu';

export default function Navbar() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        top: 0,
        px: 1.5,
        py: 0.5,
        zIndex: 10000,
        backgroundColor: 'background.body',
        borderBottom: '1px solid',
        borderColor: 'divider',
        position: 'sticky',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1.5,
        }}
      >
       <img src='https://sih-st-avatars.stocktwits-cdn.com/fit-in/50x50/filters:quality(100)/production/7900941/large-1686502504.png' width="32"/>
       <Typography component="a" href="/" level="title-lg" sx={{ textDecoration: 'none'}}>ValueAreas</Typography>
       <MobileMenu></MobileMenu>
       <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
         
        <Button
          variant="plain"
          color="neutral"
          component="a"
          href="/"
          size="sm"
          aria-pressed="true"
          sx={{ alignSelf: 'center' }}
        >
          Screener
        </Button>
        
      </Stack>
      </Box>

     

      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
        <Box
          sx={{
            gap: 1,
            alignItems: 'center',
            display: { xs: 'none', sm: 'flex' },
          }}
        >
          <UserAuth></UserAuth>
        </Box>
        <ColorSchemeToggle sx={{ alignSelf: 'center' }} />
      </Box>
    </Box>
  );
}
