import * as React from 'react';
import MenuButton from '@mui/joy/MenuButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import Apps from '@mui/icons-material/Apps';
import Dropdown from '@mui/joy/Dropdown';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useTheme } from '@mui/material/styles';
import UserAuth from './auth/UserAuth';
import { ListDivider } from '@mui/joy';

export default function MobileMenu() {
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const createHandleClose = (index) => () => {
        if (typeof index === 'number') {
            setSelectedIndex(index);
        }
    }; 
    if (matches == false) {
        return (<Dropdown sx={{ display: { xs: 'flex', sm: 'none', md: 'none', 'lg': 'none' } }}>
            <MenuButton startDecorator={<Apps />}>Apps</MenuButton>
            <Menu>
                <MenuItem component='a' href="/">
                    Screener
                </MenuItem>
                <ListDivider />
                <MenuItem>
                    <UserAuth></UserAuth>
                </MenuItem>
            </Menu>
        </Dropdown>
        );
    }

}
