import * as React from "react"

import { useEffect } from "react";
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"  
import ResultGrid from "./components/ResultGrid"
import VaCards from "./components/VaCards"
import { useScreenerStore } from './store/screener'

export default function App() {


  const runScreenerEndpoint = useScreenerStore(state => state.runScreenerEndpoint)

  const lastRun = useScreenerStore(state => state.lastRun)

  useEffect(() => {
    //Runs only on the first render
    runScreenerEndpoint();
  }, []);

  return ( 
    <> 
      <VaCards ticker='SPY' size="sm" />
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between", 
        }}
      > 
        <Typography level="h3" component="h1">
          Intraday Volume Profile Screener
        </Typography>
        <Typography level="body-sm">
          Easily identify value areas, high-volume nodes, and significant trading levels across various financial instruments to analyze market activity, understand supply and demand dynamics. Quickly scan multiple charts and timeframes to discover key areas of interest using our customizable search criteria and filter results based on specifics, such as Initial balance, previous value area, and price ranges.
        </Typography>
      </Box>
      <ResultGrid></ResultGrid>
    </> 
  )
}
