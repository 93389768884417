import { create } from "zustand";
import client from "../lib/directus";
import { readItems, createItem, deleteItem, readItem, updateItem } from '@directus/sdk';
import axios from "axios";

const BASE_URL = "https://api.valueareas.com/valueareas"
//const BASE_URL = "http://localhost:9000/valueareas"
export const useScreenerStore = create((set, get) => (
        {
                screenerStatus: true,
                screenerMessage: '',
                isRunning: false,
                limit: 25,
                page: 0,
                lastRun: '',
                screenerBeingEdited: null,
                screenerCriteria: {
                        tickers: null,
                        cOpenPosVA: null,
                        cOpenPosHL: null,
                        ibBreakStatus: null,
                        cclosestatus: null,
                        ibSize: null,
                        cibCloseStatus: null,
                        pmin: 0,
                        pmax: 800,
                        s1Hit: null,
                        s2Hit: null,
                        s3Hit: null,
                        r1Hit: null,
                        r2Hit: null,
                        r3Hit: null,
                        cibCloseStatus: null,
                        ibBreakStatus: null,
                        ibSize: null,
                },
                screenerData: [],
                myscreeners: [],
                setScreenerData: async (screenerData) => {
                        set({ screenerData: screenerData });
                },
                editScreenerById: async (id) => {
                        const screener = await client.request(readItem('screeners', id))
                        set({ screenerBeingEdited: screener });
                },
                createScreener: async (data) => {
                        const screener = await client.request(createItem('screeners', data))
                        const myscreeners = get().myscreeners;
                        myscreeners.push(screener);
                        set({ myscreeners: myscreeners });
                },
                updateScreener: async (id, data) => {
                        await client.request(updateItem('screeners', id, data))
                        get().getMyScreeners();
                },
                deleteScreener: async (id) => {
                        await client.request(deleteItem('screeners', id))
                        get().getMyScreeners();
                },
                getStock: async (ticker) => {
                        const stock = await axios.get(BASE_URL + "/stock?ticker=" + ticker.toUpperCase())
                        //console.log('stock are', stock.data)
                        if (stock.data?.length > 0) {
                                return stock.data[0];
                        } else {
                                return null;
                        }

                },
                getValueareas: async (ticker) => {
                        try {
                                const valueareas = await axios.get(BASE_URL + "?ticker=" + ticker.toUpperCase())
                                //console.log('valueareas are', valueareas.data)
                                if (valueareas.data?.length > 0) {
                                        return valueareas.data[0];
                                } else {
                                        return null;
                                }
                        } catch (e) {
                                //console.log(e.message);
                                return null;
                        }
                },
                getStocksNearPVAL: async (ticker) => {
                        const stocks = await axios.get(BASE_URL + "/stocksnearPVAL") 
                        if (stocks.data?.length > 0) {
                                return stocks.data;
                        } else {
                                return null;
                        }
                },
                getStocksNearPVAH: async (ticker) => {
                        const stocks = await axios.get(BASE_URL + "/stocksnearPVAH") 
                        if (stocks.data?.length > 0) {
                                return stocks.data;
                        } else {
                                return null;
                        }
                },
                getRelatedStocks: async (sector) => {
                        try {
                                const relatedStocks = await axios.get(BASE_URL + "/related?sector=" + sector)
                                //console.log('relatedStocks are', relatedStocks.data)
                                if (relatedStocks.data?.length > 0) {
                                        return relatedStocks.data;
                                } else {
                                        return null;
                                }
                        } catch (e) {
                                //console.log(e.message);
                                return null;
                        }
                },
                getNews: async (ticker) => {
                        const news = await axios.get(BASE_URL + "/news?ticker=" + ticker)
                        //console.log('news are', news.data)
                        if (news.data?.length > 0) {
                                return news.data;
                        } else {
                                return null;
                        }
                },
                getHistoryKLineData: async (ticker, multiplier, timespan, from, to) => {
                        const historyKLineData = await axios.get(BASE_URL + "/getHistoryKLineData?ticker=" + ticker + "&multiplier=" + multiplier + "&timespan=" + timespan + "&from=" + from + "&to=" + to)
                        //console.log('historyKLineData are', historyKLineData.data)
                        return historyKLineData.data
                },
                 
                setPage: async (page) => {
                        set({ page: page });
                },
                setLimit: async (limit) => {
                        set({ limit: limit });
                },
                setItemValue: async (item, value) => {
                        ////console.log("item", item, "value", value)
                        const screenerCriteriaLcl = get().screenerCriteria;
                        screenerCriteriaLcl[item] = value;
                        set({ screenerCriteria: screenerCriteriaLcl });
                },
                setTickers: async (tickers) => {
                        let listOfTickers = tickers.map(x => x.ticker);
                        const screenerCriteriaLcl = get().screenerCriteria;
                        screenerCriteriaLcl.tickers = listOfTickers
                        set({ screenerCriteria: screenerCriteriaLcl });
                },

                resetScreener: async () => {
                        set({
                                limit: 25, page: 0, screenerCriteria: {
                                        tickers: null,
                                        cOpenPosVA: null,
                                        cOpenPosHL: null,
                                        ibBreakStatus: null,
                                        cclosestatus: null,
                                        ibSize: null,
                                        cibCloseStatus: null,
                                        pmin: 0,
                                        pmax: 800,
                                        s1Hit: null,
                                        s2Hit: null,
                                        s3Hit: null,
                                        r1Hit: null,
                                        r2Hit: null,
                                        r3Hit: null,
                                        cibCloseStatus: null,
                                        ibBreakStatus: null,
                                        ibSize: null,
                                }
                        });
                },
                runScreenerEndpoint: async () => {
                        set({ isRunning: true });
                        set({ screenerData: [] });
                        const screenerCriteriaLcl = get().screenerCriteria;
                        let buildFilter = []
                        if (screenerCriteriaLcl.cOpenPosVA) {
                                buildFilter.push({ key: 'cOpenPosVA', value: screenerCriteriaLcl.cOpenPosVA });
                        }
                        if (screenerCriteriaLcl.cOpenPosHL) {
                                buildFilter.push({ key: 'cOpenPosHL', value: screenerCriteriaLcl.cOpenPosHL });
                        }

                        if (screenerCriteriaLcl.cclosestatus) {
                                buildFilter.push({ key: 'cclosestatus', value: screenerCriteriaLcl.cclosestatus });
                        }

                        if (screenerCriteriaLcl.cibCloseStatus) {
                                buildFilter.push({ key: 'cibCloseStatus', value: screenerCriteriaLcl.cibCloseStatus });
                        }

                        if (screenerCriteriaLcl.ibBreakStatus) {
                                buildFilter.push({ key: 'ibBreakStatus', value: screenerCriteriaLcl.ibBreakStatus });
                        }

                        if (screenerCriteriaLcl.ibSize) {
                                buildFilter.push({ key: 'ibSize', value: screenerCriteriaLcl.ibSize });
                        }

                        const queryString = buildFilter.map(param => `${param.key}=${param.value}`).join('&');


                        const screenerData = await axios.get(BASE_URL + "/screener?" + queryString)
                        //console.log('screenerData are', screenerData.data)
                        let lastRunDt = new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString();
                        let screenerData1 = screenerData.data.map(v => ({...v, type: "MASTER"}))
                        set({ screenerData: screenerData1 });
                        set({ isRunning: false });
                        set({ lastRun: lastRunDt });
                },
        }
))