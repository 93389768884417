import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import useSWR from 'swr'
import { useScreenerStore } from '../store/screener'
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/joy/Table';

export default function StockStats({ stock }) {
    const getValueareas = useScreenerStore(state => state.getValueareas)
    const fetcher = symbol => getValueareas(stock.ticker).then(r => r)
    const { data, error, isLoading } = useSWR(stock.ticker + "ValueAreas", fetcher)

    if (error) return <div>failed to load</div>
    if (isLoading) return <CircularProgress />

    if (data) {

        let intradayColor = "table-info appNeutral";
        let intradayTxt = "Inside VA"
        if (data.cClose < data.pval) {
            intradayColor = "table-danger appRed";
            intradayTxt = "Below VA"
        } else if (data.cClose > data.pvah) {
            intradayColor = "table-success appGreen";
            intradayTxt = "Above VA"
        }

        let dailyColor = "table-info appNeutral ";
        let dailyTxt = "Inside VA"
        if (data.cClose < data.wpval) {
            dailyColor = "table-danger appRed";
            dailyTxt = "Below VA"
        } else if (data.cClose > data.wpvah) {
            dailyColor = "table-success appGreen";
            dailyTxt = "Above VA"
        }

        let weeklyColor = "table-info appNeutral ";
        let weeklyTxt = "Inside VA"
        if (data.cClose < data.mpval) {
            weeklyColor = "table-danger appRed";
            weeklyTxt = "Below VA"
        } else if (data.cClose > data.mpvah) {
            weeklyColor = "table-success appGreen";
            weeklyTxt = "Above VA"
        }

        return (

            <Card sx={{ width: 360 }}>
                <div>
                    <Typography level="title-lg">{stock.ticker} Volume Profile Stats</Typography>
                </div>

                <CardContent orientation="horizontal">
                   
                        <Table size='sm'  variant={'soft'} color={'neutral'} borderAxis="both" >
                            <thead className="table-light">
                                <tr>
                                    <th scope="col"></th>
                                    <th  scope="col">Intraday</th>
                                    <th scope="col">Daily</th>
                                    <th scope="col">Weekly</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row">VAH</th>
                                    <td>{data.pvah}</td>
                                    <td>{data.wpvah}</td>
                                    <td>{data.mpvah}</td>
                                </tr>
                                <tr>
                                    <th scope="row">POC</th>
                                    <td>{data.ppoc}</td>
                                    <td>{data.wppoc}</td>
                                    <td>{data.mppoc}</td>
                                </tr>

                                <tr>
                                    <th scope="row">VAL</th>
                                    <td>{data.pval}</td>
                                    <td>{data.wpval}</td>
                                    <td>{data.mpval}</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>${data.cClose?.toFixed(2)}</td>
                                    <td className={intradayColor}>{intradayTxt}</td>
                                    <td className={dailyColor}>{dailyTxt}</td>
                                    <td className={weeklyColor}>{weeklyTxt}</td>
                                </tr>
                            </tfoot>
                        </Table> 
                </CardContent>
            </Card>
        )
    }
}
