import React from 'react'
import { Line } from "react-konva";

import { useChartStore } from "../../../store/chart";

export default function CrossHairHorizontal({ stageRef, mousePos = {} }) {
    const stageWidth = useChartStore((state) => state.stageWidth)
    const stageHeight = useChartStore((state) => state.stageHeight)
    const chartConfig = useChartStore((state) => state.chartConfig)

    const [isDragging, setIsDragging] = React.useState(false);

    const [pos, setPos] = React.useState({ x: 0, y: 0 });

    const line = {
        points: [
            0,
            mousePos.y ? (mousePos.y > stageHeight - chartConfig.ypadding ?  stageHeight - chartConfig.ypadding - 2: mousePos.y ) : stageHeight / 2,
            stageWidth - chartConfig.xpadding,
            mousePos.y ? (mousePos.y > stageHeight - chartConfig.ypadding ?  stageHeight - chartConfig.ypadding - 2: mousePos.y ) : stageHeight / 2,
        ],
        strokeWidth: 1,
        lineCap: "round",
        lineJoin: "round", 
        stroke: "black",
        dash: [2, 3],
    }

    return (
        <Line points={line.points}
            draggable
            stroke={line.stroke}
            strokeWidth={line.strokeWidth}
            dash={line.dash}
            onMouseEnter={() => {
                stageRef.current.getStage().container().style.cursor = "pointer";
            }}
            onMouseLeave={() => {
                stageRef.current.getStage().container().style.cursor = "crosshair";
            }}
            onDragStart={() => {
                stageRef.current.getStage().container().style.cursor = "grabbing";
                setIsDragging(true);
            }}
            onDragEnd={e => {
                stageRef.current.getStage().container().style.cursor = "pointer";
                setIsDragging(false);
            }}
            onDragMove={e => {
                const newPos = e.target.position();
                // reset position to its old state
                // so drag is fully controlled by react
                e.target.position({ x: pos.x, y: pos.y });

                // your set state
                setPos({
                    x: Math.min(1127, newPos.x),
                    y: Math.min(551, newPos.y)
                });
            }} />
    )
}
