import React, { useEffect } from 'react'
import { supabase } from '../../lib/supabaseClient'
import { useUserSessionStore } from '../../store/usersession'
import Profile from './Profile'
import { Box, Button, Avatar } from '@mui/joy'
import LoginIcon from '@mui/icons-material/Login';

export default function UserAuth() {

    const session = useUserSessionStore(state => state.session)
    const setSession = useUserSessionStore(state => state.setSession)

    useEffect(() => {
        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session) 
        })

        supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session)
        })
    }, [])


    const SignInComponent = () => {
        return (
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}> 
                <Button size="sm" component="a" href="/login" variant="solid" color="primary">Sign in</Button>
                <Button size="sm" component="a" href="/register" variant="solid" color="primary">Register</Button>
            </Box>
        )
    }

    return (
        <>
            {!session ? <SignInComponent></SignInComponent> : <Profile/>}
        </>
    )
}
