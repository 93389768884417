import { useState } from 'react'
import { supabase } from './lib/supabaseClient'
import * as React from "react"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Divider from "@mui/joy/Divider"
import FormControl from "@mui/joy/FormControl"
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel"
import IconButton from "@mui/joy/IconButton"
import Link from "@mui/joy/Link"
import Input from "@mui/joy/Input"
import Typography from "@mui/joy/Typography"
import Stack from "@mui/joy/Stack"
import GoogleIcon from "./components/GoogleIcon"
import ReportIcon from '@mui/icons-material/Report';
import Alert from '@mui/joy/Alert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'; 
import { useUserSessionStore } from './store/usersession'
import { useNavigate } from "react-router-dom";

export default function RegisterPage() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const navigate = useNavigate();

  const session = useUserSessionStore(state => state.session)
  const setSession = useUserSessionStore(state => state.setSession)

  const handleSignup = async (event) => {
    event.preventDefault()

    setLoading(true)
    const { data, error } = await supabase.auth.signUp({ email, password })

    if (error) {
      setError(error.error_description || error.message)
    } else {
      <Alert size="lg" color='primary' variant='outlined'>Account created! Check your email for a confirmation link. <Button size='sm' component="a" href="/" variant='soft'>Go Home</Button></Alert>
    }
    setLoading(false)
  }


  return (
    <Box>
      {session && <Box
        component="main"
        sx={{
          my: "auto",
          py: 2,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 500,
          maxWidth: "100%",
          mx: "auto",
          borderRadius: "sm",
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: 2
          },
          [`& .${formLabelClasses.asterisk}`]: {
            visibility: "hidden"
          }
        }}
      >
        <Alert size="lg" variant='outlined'>You are already logged in! <Button size='sm' component="a" href="/" variant='soft'>Go Home</Button></Alert>
        </Box>}
      {!session && <Box
        component="main"
        sx={{
          my: "auto",
          py: 2,
          pb: 5,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          width: 400,
          maxWidth: "100%",
          mx: "auto",
          borderRadius: "sm",
          "& form": {
            display: "flex",
            flexDirection: "column",
            gap: 2
          },
          [`& .${formLabelClasses.asterisk}`]: {
            visibility: "hidden"
          }
        }}
      >
        <Stack gap={4} sx={{ mb: 2 }}>
          <Stack gap={1}>
            <Typography level="h3">Create an account</Typography>
            <Typography level="body-sm">
              Already have an account?{" "}
              <Link href="/login" level="title-sm">
                Login!
              </Link>
            </Typography>
          </Stack> 
        </Stack> 
        <Stack gap={4} sx={{ mt: 2 }}>
          {error && <Alert
            key="error"
            sx={{ alignItems: 'flex-start' }}
            startDecorator={<ReportIcon />}
            variant="soft"
            color={'danger'}
            endDecorator={
              <IconButton variant="soft" color={'danger'} onClick={() => { setError(null) }}>
                <CloseRoundedIcon />
              </IconButton>
            }
          >
            <div>
              <div>Error</div>
              <Typography level="body-sm" color={'danger'}>
                {error}
              </Typography>
            </div>
          </Alert>}
          <form onSubmit={handleSignup}>
            <FormControl required>
              <FormLabel>Email</FormLabel>
              <Input type="email" name="email" onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl required>
              <FormLabel>Password</FormLabel>
              <Input type="password" name="password" onChange={(e) => setPassword(e.target.value)} />
            </FormControl>
            <Stack gap={4} sx={{ mt: 2 }}> 
              <Button type="submit" fullWidth>
                Create an account
              </Button>
            </Stack>
          </form>
        </Stack>
      </Box>
      }
    </Box>
  )
}
