import * as React from "react"

import { CssVarsProvider } from "@mui/joy/styles"
import CssBaseline from "@mui/joy/CssBaseline"
import Box from "@mui/joy/Box"
import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider } from "@mui/material";
import { appTheme } from "./lib/theme";

import Sidebar from "./components/Sidebar"
import Header from "./components/Header"
import { Outlet, useLocation } from "react-router-dom";
import HorizontalHeader from "./components/HorizontalHeader";
import Navbar from "./components/Navbar";

export default function Layout() {
  const theme = useTheme();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { hash, pathname, search } = useLocation();
  const isChartPage = pathname.includes('/chart/')
  return (
    <ThemeProvider theme={appTheme}>
      <CssVarsProvider disableTransitionOnChange>
        <CssBaseline />
        <Navbar></Navbar> 
        <Box sx={{ display: "flex", minHeight: "100dvh" }}>
          <Box
            component="main"
            className="MainContent"
            sx={{
              px: { xs: 1, md: 1 },
              pt: {
                xs: "calc(12px + var(--Header-height))",
                sm: "calc(12px + var(--Header-height))",
                md: 1
              },
              pb: { xs: 2, sm: 2, md: 3 },
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              height: matches == false ? "100%" : "100dvh",
              gap: 1,
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </CssVarsProvider>
    </ThemeProvider>
  )
}
