import * as React from 'react';


import { useScreenerStore } from "../../store/screener";
import Tooltip from '@mui/joy/Tooltip';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function IbBreakoutFilter() {
    const setItemValue = useScreenerStore(state => state.setItemValue)
    let itemValue = useScreenerStore((state) => state.screenerCriteria.ibBreakStatus)

    const handleChange = async (event, newItemValue) => {
        console.log('newItemValue', newItemValue)
        if (itemValue == newItemValue || newItemValue == "" || newItemValue == null) {
            newItemValue = null
        }
        await setItemValue('ibBreakStatus', newItemValue);
    };

    //console.log('render valuearea filter')
    //console.log('itemValue', itemValue)

    if (itemValue == null) {
        itemValue = ""
    }

    return (
        <FormControl size="sm">
            <Tooltip title="Filter current day's price action relative to the day's initial balance (9.30am to 10.30am EST price range). 
             If the stock price breaks above the initial balance high, it is considered to be a bullish sign. If the stock price breaks below the initial balance low, it is considered to be a bearish sign." variant="solid" arrow placement="top">
                <FormLabel>IB:Test<InfoOutlinedIcon /> </FormLabel></Tooltip>
            <Select
                size="sm"
                placeholder="Filter by status"
                value={itemValue}
                onChange={handleChange}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            >
                <Option value="">Any</Option>
                <Option value="Broken - Up">Broken - Up</Option>
                <Option value="Broken - BothSides">Broken - BothSides</Option>
                <Option value="Broken - Down">Broken - Down</Option>
            </Select>
        </FormControl>
    );
}