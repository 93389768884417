import * as React from 'react';


import { useScreenerStore } from "../../store/screener";
import Tooltip from '@mui/joy/Tooltip';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function PdhlFilter() {
    const setItemValue = useScreenerStore(state => state.setItemValue)
    let itemValue = useScreenerStore((state) => state.screenerCriteria.cOpenPosHL)

    const handleChange = async (event, newItemValue) => {
        console.log('newItemValue', newItemValue)
        if (itemValue == newItemValue || newItemValue == "" || newItemValue == null) {
            newItemValue = null
        }
        await setItemValue('cOpenPosHL', newItemValue);
    };

    //console.log('render valuearea filter')
    //console.log('itemValue', itemValue)

    if (itemValue == null) {
        itemValue = ""
    }

    return (
        <FormControl size="sm">
            <Tooltip title="Filter current day's stock open price (9.30am EST) relative to the previous day's high and low. Trading above previous day's high-low range is considered bullish and trading below previous day's high-low is generally bearish." variant="solid" arrow placement="top">
                <FormLabel>OPEN:PH-L <InfoOutlinedIcon /> </FormLabel></Tooltip>
            <Select
                size="sm"
                placeholder="Filter by status"
                value={itemValue}
                onChange={handleChange}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            >
                <Option value="">Any</Option>
                <Option value="Open Above PDHL">Open Above PDHL</Option>
                <Option value="Open Inside PDHL">Open Inside PDHL</Option>
                <Option value="Open Below PDHL">Open Below PDHL</Option>
            </Select>
        </FormControl>
    );
}