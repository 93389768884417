import React from 'react'

import Box from '@mui/joy/Box'; 
 import { ToggleButtonGroup, Button, Divider, Typography } from '@mui/joy';
 import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent'; 
import { useChartStore } from "../../../store/chart";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


export default function ChartTopToolbar({deleteSelected}) {
    const currentPendingDrawing = useChartStore(state => state.currentPendingDrawing)
    const setCurrentPendingDrawing = useChartStore(state => state.setCurrentPendingDrawing)

    const drawHorizontalLine = () => {
        setCurrentPendingDrawing('ADD_HORIZONTAL_LINE')
    }
    const drawVerticalLine = () => {
        setCurrentPendingDrawing('ADD_VERTICAL_LINE')
    }
    const drawUpArrow = () => {
        setCurrentPendingDrawing('ADD_UP_ARROW')
    }
    const drawDownArrow = () => {
        setCurrentPendingDrawing('ADD_DOWN_ARROW')
    }
      
    return (
        <>
        <Box>
            <Card variant="outlined">
            <CardContent>
            <Typography level="title-md">Outlined card (default)</Typography>
            <Typography>Description of the card.</Typography>
            </CardContent>
        </Card>
        </Box>
        <Box
            elevation={0}
            sx={{
                display: 'flex',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 0,
                flexWrap: 'wrap',
            }}
        > 
            <ToggleButtonGroup
                size="sm"
                exclusive
                aria-label="text alignment"
            >
                <Button value="left" aria-label="left aligned" sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <Typography fontWeight='bold'>Intraday</Typography>
                </Button>
                <Button value="center" aria-label="centered" sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <Typography fontWeight='bold'> Daily</Typography>
                </Button>
                <Button value="right" aria-label="right aligned" sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <Typography fontWeight='bold'> Weekly</Typography>
                </Button> 
            </ToggleButtonGroup>
            <Box flexItem  sx={{ ml: 1, mr: 1}} />
            <ToggleButtonGroup
                size="sm"
                exclusive
                aria-label="text alignment"
                orientation='horizontal'
            >
                <Button value="left" aria-label="left aligned" sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor"><path d="M18 15h8v-1h-8z"></path><path d="M14 18v8h1v-8zM14 3v8h1v-8zM3 15h8v-1h-8z"></path></g></svg>
                </Button>
                <Button value="center" aria-label="centered" onClick={drawHorizontalLine} sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><g fill="currentColor" ><path d="M4 15h8.5v-1h-8.5zM16.5 15h8.5v-1h-8.5z"></path><path d="M14.5 16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"></path></g></svg>
                </Button>
                <Button value="center" aria-label="centered" onClick={drawVerticalLine} sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 28 28"
                        width="28"
                        height="28"
                    >
                        <g fill="currentColor">
                            <path d="M15 12.5v-8.5h-1v8.5zM14 16.5v8.5h1v-8.5z"></path>
                            <path
                                d="M14.5 16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"
                            ></path>
                        </g>
                    </svg>
                </Button>
                
                <Button value="justify" aria-label="justified" onClick={drawUpArrow} sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M11 16v6h6v-6h4.865l-7.865-9.438-7.865 9.438h4.865zm7 7h-8v-6h-6l10-12 10 12h-6v6z"></path></svg>
                </Button>
                <Button value="justify" aria-label="justified" onClick={drawDownArrow} sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M17 12v-6h-6v6h-4.865l7.865 9.438 7.865-9.438h-4.865zm-7-7h8v6h6l-10 12-10-12h6v-6z"></path></svg>
                </Button>
            </ToggleButtonGroup>
            <Divider flexItem orientation="horizontal" sx={{ mx: 0.5, my: 1 }} />
            <ToggleButtonGroup
                size="sm"
                exclusive
                aria-label="text alignment"
                orientation='horizontal'
            >
                <Button onClick={deleteSelected} value="justify" color='secondary' aria-label="justified" sx={{ borderRadius: 0, borderTop: 0, borderBottom: 0}}>
                    <DeleteForeverIcon />
                </Button>
            </ToggleButtonGroup>
        </Box>
        </>
    )
}
