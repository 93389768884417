import React from 'react';
import Grid from '@mui/joy/Grid';
import ChartTopToolbar from './layout/ChartTopToolbar'; 
import ChartStage from './ChartStage';
import VaCards from '../VaCards'; 
import { useChartStore } from "../../store/chart";

export default function KChart({stock}) {
    const shapes = useChartStore((state) => state.shapes)
    const setShapes = useChartStore((state) => state.setShapes)
    const selectedId = useChartStore((state) => state.selectedId)
    const setSelectedId = useChartStore((state) => state.setSelectedId)  

    const deleteSelectedShape = () => { 
        const newShapes = shapes.filter((shape) => shape.shapeProps.id !== selectedId);
        setShapes(newShapes);
        setSelectedId(null);
    }

    return (
        <Grid container display='flex' justifyContent={'center'} alignContent={'center'} direction='column'>
            <Grid item> 
            <VaCards />
                <ChartTopToolbar deleteSelected={deleteSelectedShape} />
            </Grid>
            <Grid item sx={{ border: '1px solid #ddd', borderTop: '0px'}}> 
                <ChartStage stock={stock} />  
            </Grid>
        </Grid>
    )
}
