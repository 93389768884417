import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import * as React from 'react';

export default function AboveValue() {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Chip variant="soft" color='success' startDecorator={<TrendingUpIcon />}>
        Above Previous Value Area
      </Chip> 
    </Box>
  );
}