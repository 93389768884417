import React from 'react'
import MiniChart from './MiniChart'
import StockStats from './Stockstats'
import Grid from '@mui/joy/Grid';
import Stack from '@mui/joy/Stack';
import VaCards from './VaCards';
import { Box } from '@mui/joy';
import SRStats from './SRStats';

export default function MiniDetail({ row }) {
  return ( 
    <Stack direction={{ xs: 'row', sm: 'row' }} gap={0}>
      <MiniChart row={row} />
      <Stack direction={'column'}>
        <Box sx={{ paddingTop: 2 }}>
          <VaCards ticker={row.ticker} dir="column" />
        </Box>
        <Box sx={{ paddingTop: 2 }}>
        <StockStats stock={row} />
        </Box>
      </Stack>
      <Box sx={{ paddingTop: 2, ml: 2 }}>
      <SRStats stock={row} />
      </Box>
    </Stack> 
  )
}
