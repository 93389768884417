import React from 'react'
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import IconButton from '@mui/joy/IconButton';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import { useScreenerStore } from '../../store/screener'
import CircularProgress from '@mui/material/CircularProgress';
import useSWR from 'swr'
import StocksNearPVALTable from './StocksNearPVALTable';

export default function StocksNearPVAL() {
    const getStocksNearPVAL = useScreenerStore(state => state.getStocksNearPVAL)
    const fetcher = symbol => getStocksNearPVAL().then(r => r)
    const { data, error, isLoading } = useSWR("getStocksNearPVAL", fetcher)

    if (error) return <div>failed to load</div>
    if (isLoading) return <CircularProgress /> 

    if (data) {
        return (
            <Card variant="outlined">
                <Typography level="title-md">Stocks trading near PVA Low</Typography>
                <Typography level='body-xs'>2nd feb 2.30pm</Typography>
                <IconButton
                    variant="plain"
                    color="neutral"
                    size="sm"
                    sx={{ position: 'absolute', top: '0.875rem', right: '0.5rem' }}
                >
                    <BookmarkAdd />
                </IconButton>
                <CardContent> 
                    <StocksNearPVALTable stockdata={data}></StocksNearPVALTable>
                </CardContent>
            </Card>
        )
    }
}
