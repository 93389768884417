import React from 'react'
import { Group, Line, Shape, Transformer, Circle } from "react-konva";
import { useChartStore } from "../../../store/chart";

export default function DownArrowTool({ shapeProps, isSelected, onSelect, onChange, stageRef }) {
    const stageWidth = useChartStore((state) => state.stageWidth)
    const stageHeight = useChartStore((state) => state.stageHeight)
    const chartConfig = useChartStore((state) => state.chartConfig)
    const [isDragging, setIsDragging] = React.useState(false);
    const shapeRef = React.useRef();
    const trRef = React.useRef();


    const headWidth = shapeProps?.width / 2;
    const headHeight = shapeProps?.height / 3;
    const bodyHeight = shapeProps?.height - headHeight;
 

    if (!shapeProps) return null;

    return (
        <>
            <Shape
                ref={shapeRef}
                x={shapeProps.x - headWidth}
                y={shapeProps.y - bodyHeight}
                onClick={onSelect}
                onTap={onSelect}
                sceneFunc={(context, shape) => {
                    // Draw the rectangle
                    context.beginPath();
                    context.rect(shapeProps.width / 4, -bodyHeight, headWidth, bodyHeight);
                    context.closePath();
                    context.fillStrokeShape(shape);

                    context.beginPath();
                    context.moveTo(0, 0);
                    context.lineTo(shapeProps.width / 2, shapeProps.height);
                    context.lineTo(shapeProps.width, 0);
                    context.closePath();
                    context.fillStrokeShape(shape);

                    
                }}
                draggable
                fill={isDragging ? " red" : shapeProps.color}
                stroke={shapeProps.color}
                onDragStart={() => {
                    stageRef.current.getStage().container().style.cursor = "grabbing";
                    setIsDragging(true);
                }}
                onDragEnd={e => {
                    onChange({
                        ...shapeProps,
                        x: e.target.x() + headWidth,
                        y: e.target.y() + bodyHeight,
                    });
                    stageRef.current.getStage().container().style.cursor = "pointer";
                    setIsDragging(false);
                }}
                onDragMove={e => {

                }} 
            /> 
        </>
    )
}
