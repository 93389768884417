import React from 'react'
import useSWR from 'swr'
import { useParams  } from "react-router-dom"; 
import { useScreenerStore } from './store/screener'
import CircularProgress from '@mui/joy/CircularProgress';
import VaCards from "./components/VaCards"
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography" 
import KChart from './components/kchart/KChart';



export default function ChartPage({ params }) {
     let { symbol } = useParams();  
    const getStock = useScreenerStore(state => state.getStock)
    const fetcher = symbol => getStock(symbol).then(r => r)
    const { data, error, isLoading } = useSWR(symbol, fetcher)

    if (error) return <div>failed to load</div>

    if (isLoading) return (<div>
        <main>
            <div class="container">
                <div class="row g-1 text-center">
                    <CircularProgress />
                </div>
            </div>
        </main>
    </div>)

    if (data) {
        return (
            <> 
            <Box
              sx={{
                display: "flex",
                mb: 1,
                gap: 1,
                flexDirection: { xs: "column", sm: "row" },
                alignItems: { xs: "start", sm: "center" },
                flexWrap: "wrap",
                justifyContent: "space-between"
              }}
            >
              <Typography level="h3" component="h1">
                {data.ticker} Intraday Volume Profile Chart
              </Typography>
              <Typography level="body-sm">
                Easily identify value areas, high-volume nodes, and significant trading levels across various financial instruments to analyze market activity, understand supply and demand dynamics. Quickly scan multiple charts and timeframes to discover key areas of interest using our customizable search criteria and filter results based on specifics, such as Initial balance, previous value area, and price ranges.
              </Typography>
            </Box>
            <KChart stock={data}></KChart>
            </>
        )
    }
}
