import * as React from 'react';


import { useScreenerStore } from "../../store/screener";
import Tooltip from '@mui/joy/Tooltip';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function IbsizeFilter() {
    const setItemValue = useScreenerStore(state => state.setItemValue)
    let itemValue = useScreenerStore((state) => state.screenerCriteria.ibSize)

    const handleChange = async (event, newItemValue) => {
        console.log('newItemValue', newItemValue)
        if (itemValue == newItemValue || newItemValue == "" || newItemValue == null) {
            newItemValue = null
        }
        await setItemValue('ibSize', newItemValue);
    };

    //console.log('render valuearea filter')
    //console.log('itemValue', itemValue)

    if (itemValue == null) {
        itemValue = ""
    }

    return (
        <FormControl size="sm">
            <Tooltip title="Filter based on the size of the current day's initial balance range. A narrow initial balance range is considered to be a sign of low volatility and a wide initial balance range is considered to be a sign of high volatility." variant="solid" placement="top">
                <FormLabel>IB Size<InfoOutlinedIcon /> </FormLabel></Tooltip>
            <Select
                size="sm"
                placeholder="Filter by status"
                value={itemValue}
                onChange={handleChange}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            >
                <Option value="">Any</Option>
                <Option value="narrow">Narrow</Option>
                <Option value="normal">Normal</Option>
                <Option value="wide">Wide</Option>
                <Option value="very wide">Very wide</Option>
            </Select>
        </FormControl>
    );
}