import * as React from "react"

import { useRef, useEffect, useState } from "react";
import { CssVarsProvider } from "@mui/joy/styles"
import CssBaseline from "@mui/joy/CssBaseline"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Breadcrumbs from "@mui/joy/Breadcrumbs"
import Link from "@mui/joy/Link"
import Typography from "@mui/joy/Typography"

import HomeRoundedIcon from "@mui/icons-material/HomeRounded"
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded"
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded"

import { ThemeProvider } from "@mui/material";
import { appTheme } from "./lib/theme";

import OrderTable from "./components/OrderTable"
import ResultGrid from "./components/ResultGrid"
import OrderList from "./components/OrderList"
import VaCards from "./components/VaCards"
import { useScreenerStore } from './store/screener'
import DashboardCards from "./components/DashboardCards";

export default function DashboardPage() {


  const runScreenerEndpoint = useScreenerStore(state => state.runScreenerEndpoint)

  const lastRun = useScreenerStore(state => state.lastRun)

  useEffect(() => {
    //Runs only on the first render
    runScreenerEndpoint();
  }, []);

  return ( 
    <>
      <VaCards ticker='SPY' size="sm" />
      <Box
        sx={{
          display: "flex",
          mb: 1,
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "start", sm: "center" },
          flexWrap: "wrap",
          justifyContent: "space-between"
        }}
      >
        <Typography level="h3" component="h1">
          Volume Profile Dashboard
        </Typography>
        <Typography level="body-sm">
          Easily identify value areas, high-volume nodes, and significant trading levels across various financial instruments to analyze market activity, understand supply and demand dynamics. Quickly scan multiple charts and timeframes to discover key areas of interest using our customizable search criteria and filter results based on specifics, such as Initial balance, previous value area, and price ranges.
        </Typography>
      </Box>
      <DashboardCards></DashboardCards>
    </> 
  )
}
