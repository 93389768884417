import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import BookmarkAdd from '@mui/icons-material/BookmarkAddOutlined';
import useSWR from 'swr'
import { useScreenerStore } from '../store/screener'
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/joy/Table';
import Chip from '@mui/joy/Chip';

export default function SRStats({ stock }) {
    const getValueareas = useScreenerStore(state => state.getValueareas)
    const fetcher = symbol => getValueareas(stock.ticker).then(r => r)
    const { data, error, isLoading } = useSWR(stock.ticker + "ValueAreas", fetcher)

    if (error) return <div>failed to load</div>
    if (isLoading) return <CircularProgress />

    let PriceLevel = () => {
        return (<tr><td className="table-success appNeutral"><Typography textAlign={'center'} variant="solid" color="primary">{data.ticker} </Typography></td><td> ${data.cClose}</td></tr>)
    }

    if (data) {
        return (

            <Card sx={{ width: 160 }}>
                <div>
                    <Typography level="title-lg">{stock.ticker} Fib Zones</Typography>
                </div>
                <CardContent orientation="horizontal">
                    <Table size='sm' variant={'soft'} color={'neutral'}  >
                        <thead className="table-light">
                            <tr>
                                <th scope="col">Zone</th>
                                <th scope="col">Target</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.cClose > data.r3 && <PriceLevel/>}
                            <tr>
                                <th scope="row">R3</th>
                                <td>${data.r3.toFixed(2)}</td>
                            </tr>
                            {data.cClose > data.r2 && data.cClose < data.r3 && <PriceLevel/>}
                            <tr>
                                <th scope="row">R2</th>
                                <td>${data.r2.toFixed(2)}</td>
                            </tr>
                            {data.cClose > data.r1 && data.cClose < data.r2 && <PriceLevel/>}
                            <tr>
                                <th scope="row">R1</th>
                                <td>${data.r1.toFixed(2)}</td>
                            </tr>
                            {data.cClose > data.s1 && data.cClose < data.r1 && <PriceLevel/>}
                            <tr>
                                <th scope="row">S1</th>
                                <td>${data.s1.toFixed(2)}</td>
                            </tr>
                            {data.cClose > data.s2  && data.cClose < data.s1 && <PriceLevel/>}
                            <tr>
                                <th scope="row">S2</th>
                                <td>${data.s2.toFixed(2)}</td>
                            </tr>
                            {data.cClose > data.s3  && data.cClose < data.s2 && <PriceLevel/>}
                            <tr>
                                <th scope="row">S3</th>
                                <td>${data.s3.toFixed(2)}</td>
                            </tr>
                            {data.cClose < data.s3 && <PriceLevel/>}  
                        </tbody>
                    </Table>
                </CardContent>
            </Card>
        )
    }
}
