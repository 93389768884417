import * as React from 'react';
import { useState, useMemo } from 'react';
import Table from '@mui/joy/Table';
import { Typography } from '@mui/joy';
import DataGrid from 'react-data-grid';
 

function formatCompactNumber(number) {
    const formatter = Intl.NumberFormat("en", { notation: "compact" });
    return formatter.format(number);
}


const columns = [
     
    {
        key: 'ticker', name: 'Ticker', 
        
        renderCell({ row, tabIndex, onRowChange }) {
           

            return (
               <div> {row.ticker}</div>
            );
        }
    },
    {
        key: 'cClose', name: 'Last',   renderCell(props) {
            const value = props.row.cClose;
            if (value) {
                return (
                    <span style={{ fontSize: '11px', }}>
                        ${value.toFixed(2)}
                    </span>
                );
            }
        },
        
    },
    {
        key: 'changep', name: 'Change',   renderCell(props) {
            const valuev = props.row.changev;
            const valuep = props.row.changep;
            if (valuep && valuev) {
                const displayValue = '$' + valuev.toFixed(2) + " (" + valuep.toFixed(2) + "%)";
                return (
                    <span style={{ fontSize: '11px', }}>
                        ${valuev.toFixed(2)}
                    </span>
                );
            }
        },
         
    },

    {
        key: 'cvolume', name: 'Volume',   renderCell(props) {
            const valuev = props.row.cvolume;

            return (
                <span style={{ fontSize: '11px', }}>
                    {formatCompactNumber(valuev)}
                </span>
            );

        },
         
    },
    {
        key: 'pvah', name: 'PVAH',  renderCell(props) {
            const valuev = props.row.pvah;

            return (
                <span style={{ fontSize: '11px', }}>
                    ${valuev.toFixed(2)}
                </span>
            );

        },
         
    },

];

export default function StocksNearPVAHTable({ stockdata }) {
    const [sortColumns, setSortColumns] = useState([]);
    const rows = stockdata;
    function EmptyRowsRenderer() {
        return (
            <div style={{ textAlign: 'center', marginTop: '10px', gridColumn: '1/-1' }}>
                No matching symbols found
            </div>
        );
    }

    function getComparator(sortColumn) {
        switch (sortColumn) {
            case "cclosestatus":
            case "cOpenPosVA":
            case "cOpenPosHL":
            case "ibBreakStatus":
            case "cibCloseStatus":
            case "ibSize":
            case 'ticker':
                return (a, b) => {
                    return a[sortColumn]?.localeCompare(b[sortColumn]);
                };
            case "changep":
            case 's1Hit':
            case 's2Hit':
            case 's3Hit':
            case 'r1Hit':
            case 'r2Hit':
            case 'r3Hit':
            case 'cvolume':
            case 'pval':
            case 'cClose':
                return (a, b) => {
                    return a[sortColumn] - b[sortColumn];
                };
            default:
                throw new Error(`unsupported sortColumn: "${sortColumn}"`);
        }
    }

    let sortedRows = useMemo(() => {
        if (sortColumns.length === 0) return rows;

        return [...rows].sort((a, b) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0;
        });
    }, [rows, sortColumns]);

    function onRowsChange(rows, { indexes }) {
        const row = rows[indexes[0]];
        //console.log('row changed', row);
        if (row.type === 'MASTER') {
            if (row.expanded) {
                rows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    id: row.id + 1,
                    parentId: row.id,
                    ticker: row.ticker,
                    row: row
                });
            } else {
                rows.splice(indexes[0] + 1, 1);
            }
            //console.log('rows are', rows)
            //setScreenerData(rows); // update the store
            sortedRows = rows;
        }
    }

    return (
        <DataGrid
            columns={columns}
            rows={sortedRows}
            className={"rdg-light"}
            onRowsChange={onRowsChange}
            enableVirtualization={true}
            rowHeight={(row) => (row.type === 'DETAIL' ? '520' : 25)}
            defaultColumnOptions={{
                sortable: true,
                resizable: true
            }}
            renderers={{ noRowsFallback: <EmptyRowsRenderer /> }}
            sortColumns={sortColumns}
            onSortColumnsChange={setSortColumns} />
    );
}