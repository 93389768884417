import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Sun from '@mui/icons-material/LightMode';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import * as React from 'react';

export default function BelowValue() {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Chip variant="soft" color='danger' startDecorator={<TrendingDownIcon />}>
        Below Previous Value Area
      </Chip> 
    </Box>
  );
}