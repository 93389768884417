import * as React from 'react';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import StocksNearPVAL from './dashboard/StocksNearPVAL';
import StocksNearPVAH from './dashboard/StocksNearPVAH';
 

const Item = styled(Sheet)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
    ...theme.typography['body-sm'],
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 4,
    color: theme.vars.palette.text.secondary,
}));


export default function DashboardCards() {
    return (
        <Grid container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, sm: 8, md: 12 }} sx={{ flexGrow: 1 }}>
            <Grid xs>
                <StocksNearPVAL></StocksNearPVAL>
            </Grid>
            <Grid xs>
                 <StocksNearPVAH></StocksNearPVAH>
            </Grid>
            <Grid xs>
                <Card variant="outlined">
                    <CardContent>
                        <Typography level="title-md">Outlined card (default)</Typography>
                        <Typography>Description of the card.</Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>

    )
}
