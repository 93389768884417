import * as React from 'react';


import { useScreenerStore } from "../../store/screener";
import Tooltip from '@mui/joy/Tooltip';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function OpenPostVAFilter() {
    const setItemValue = useScreenerStore(state => state.setItemValue)
    let itemValue = useScreenerStore((state) => state.screenerCriteria.cOpenPosVA)

    const handleChange = async (event, newItemValue) => {
        console.log('newItemValue', newItemValue)
        if (itemValue == newItemValue || newItemValue == "" || newItemValue == null) {
            newItemValue = null
        }
        await setItemValue('cOpenPosVA', newItemValue);
    };

    //console.log('render valuearea filter')
    //console.log('itemValue', itemValue)

    if (itemValue == null) {
        itemValue = ""
    }

    return (
        <FormControl size="sm">
            <Tooltip title="Filter current day's stock open price (9.30am EST) relative to the previous day's value area. If the stock opens above the previous day's value area high, it is considered to be a bullish sign. If the stock opens below the previous day's value area low, it is considered to be a bearish sign." variant="solid" arrow placement="top"><FormLabel>OPEN:PVA <InfoOutlinedIcon /> </FormLabel></Tooltip>
            <Select
                size="sm"
                placeholder="Filter by status"
                value={itemValue}
                onChange={handleChange}
                slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
            >
                <Option value="">Any</Option>
                <Option value="Open Above PVAH">Open Above PVAH</Option>
                <Option value="Open Inside PVA">Open Inside PVA</Option>
                <Option value="Open Below PVAL">Open Below PVAL</Option>
            </Select>
        </FormControl>
    );
}