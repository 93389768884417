import React from 'react'

import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';
import AboveValue from './VaCards/AboveValue';
import BelowValue from './VaCards/BelowValue';
import InsideValue from './VaCards/InsideValue';
import useSWR from 'swr'
import { useScreenerStore } from '../store/screener'
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/joy';
import Divider from '@mui/joy/Divider';


export default function VaCards({ ticker, dir, size }) {
    const getValueareas = useScreenerStore(state => state.getValueareas)
    const fetcher = symbol => getValueareas(ticker).then(r => r)
    const { data, error, isLoading } = useSWR(ticker + "ValueAreas", fetcher)

    if (error) return <div>failed to load</div>
    if (isLoading) return <CircularProgress />

    if (data) {

        let IntradayStatus = () => {
            if (data.cClose < data.pval) {
                return <BelowValue />;
            } else if (data.cClose > data.pvah) {
                return <AboveValue />;
            } else {
                return <InsideValue />;
            }
        };

        let DailyStatus = () => {
            if (data.cClose < data.wpval) {
                return <BelowValue />;
            } else if (data.cClose > data.wpvah) {
                return <AboveValue />;
            } else {
                return <InsideValue />;
            }
        };

        let WeeklyStatus = () => {
            if (data.cClose < data.mpval) {
                return <BelowValue />;
            } else if (data.cClose > data.mpvah) {
                return <AboveValue />;
            } else {
                return <InsideValue />;
            }
        };

        const today = new Date();
        var offset = -300; //Timezone offset for EST in minutes.
        var estDate = new Date(today.getTime() + offset * 60 * 1000);
        const formattedDate = estDate.toISOString().split('T')[0];


        if (size == 'sm') {
            return (
                <Stack spacing={1} direction={{ xs: 'column', sm: dir == 'column' ? 'column' : 'row' }} divider={<Divider orientation="vertical" />}>
                    <Card size="sm" sx={{ width: 360, background: '#fff' }}>
                        <Stack direction='row' gap={1}><Typography level="title-sm">{ticker} Intraday </Typography><IntradayStatus /></Stack>
                        <img
                            src={"https://api.valueareas.com/chartmaker/" + ticker + "/30/" + formattedDate + "/320/190/0/0/D/0"}
                            loading="lazy"
                            alt=""
                        />
                    </Card>
                    <Card size="sm" sx={{ width: 360, background: '#fff' }}>
                        <Stack direction='row' gap={1}><Typography level="title-sm">{ticker} Daily </Typography><DailyStatus /></Stack>
                        <img
                            src={"https://api.valueareas.com/chartmaker/" + ticker + "/240/" + formattedDate + "/320/190/0/0/W/0"}
                            loading="lazy"
                            alt=""
                        />
                    </Card>
                    <Card size="sm" sx={{ width: 360, background: '#fff' }}>
                        <Stack direction='row' gap={1}><Typography level="title-sm">{ticker} Weekly</Typography><WeeklyStatus /></Stack>
                        <img
                            src={"https://api.valueareas.com/chartmaker/" + ticker + "/1/" + formattedDate + "/320/190/0/0/M/0"}
                            loading="lazy"
                            alt=""
                        />
                    </Card>
                </Stack>
            )
        } else {
            return (<Stack spacing={1} direction={{ xs: 'column', sm: dir == 'column' ? 'column' : 'row' }}>
                <Card size="sm" sx={{ width: 360 }}>
                    <Typography level="title-sm">{ticker} Intraday</Typography> <IntradayStatus />
                </Card>
                <Card size="sm" sx={{ width: 360 }}>
                    <Typography level="title-sm">{ticker} Daily</Typography> <DailyStatus />
                </Card>
                <Card size="sm" sx={{ width: 360 }}>
                    <Typography level="title-sm">{ticker} Weekly</Typography> <WeeklyStatus />
                </Card>
            </Stack>)
        }
    }
}
