import { create } from "zustand";
import axios from "axios";

export const useChartStore = create((set, get) => (
    {
        stageWidth: 1030,
        stageHeight: 580,
        chartConfig: {
            volbarsHeight: 75,
            xpadding: 83,
            ypadding: 29,
        },
        currentEditingItem: null,
        currentPendingDrawing: null,
        isDrawing: false,
        showHorizontalCrosshair: true,
        showVerticalCrosshair: true, 
        horizontalLines: [],
        verticalLines: [],
        rectangles: [],

        shapes: [],
        selectedId: null,
        setSelectedId: (id) => set({ selectedId: id }),
        addShape: (payload, type) => {
            console.log('addShape', payload)
            const shapes = get().shapes;
            shapes.push({shapeProps: payload, type: type});
            set({ shapes: shapes });
            set({ isDrawing: false });
            set({ currentPendingDrawing: null }); 
        },
        setShapes: (payload) => {
            console.log('setShapes', payload)
            set({ shapes: payload });
        }, 
        setCurrentPendingDrawing: (action) => {
            console.log('setCurrentPendingDrawing', action)
            set({ isDrawing: true });
            set({ currentPendingDrawing: action });  
        },
        removeCurrentPendingDrawing: () => {
            console.log('removeCurrentPendingDrawing')
            set({ isDrawing: false });
            set({ currentPendingDrawing: null });  
        },
    }
))