import React from 'react' 
export default function TickerCellExpanderFormatter({
    tabIndex,
    row,
    expanded,
    onCellExpand
}) {

    function handleKeyDown(e) {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            onCellExpand();
        }
    }

    return (
        <div className="cellExpand">
            <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
                <span tabIndex={tabIndex}><b>{row.ticker}</b></span>
            </span>
        </div>
    )
}
