import React from 'react'
import { Box, Typography, IconButton, Avatar } from '@mui/joy'
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded"
import { supabase } from '../../lib/supabaseClient'
import { useUserSessionStore } from '../../store/usersession'
import Tooltip from '@mui/joy/Tooltip';
export default function Profile() {

    const setSession = useUserSessionStore(state => state.setSession)
    const session = useUserSessionStore(state => state.session)

    const handleSignout = () => {
        supabase.auth.signOut();
        setSession(null);
    }

    return (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Avatar
                variant="solid"
                size="sm"
                color='primary'
            />
            <Box sx={{ minWidth: 0, flex: 1 }}>
                <Typography level="body-xs">{session?.user.email.substring(0, 15)} {session?.user.email.length >= 15 && '...'}</Typography>
            </Box>
            <Tooltip title="logout">
                <IconButton size="sm" variant="plain" color="neutral" onClick={handleSignout}>
                    <LogoutRoundedIcon />
                </IconButton>
            </Tooltip>

        </Box>
    )
}
